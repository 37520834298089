import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactSelect from "../../UI/ReactSelect";
import ScrapeTemplatePathPart from "../components/ScrapeTemplatePathPart";
import DatePathPart from "../components/DatePaths";
import { useCreateTemplatesMutation } from "redux/api/ScrapeTemplates/templatesApi";
import RenderIf from "app/modules/UI/RenderIf";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const GreenCheckbox = withStyles({
  root: {
    color: "green",
    "&$checked": {
      color: "green",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function Create(props) {
  const { show, onHide, id } = props;

  const [isInfinity, setIsInfinity] = useState(false);
  const [option, setOption] = useState({ value: 1, label: "DateInList" });
  const [datePathString, setDatePathString] = useState("Date Path (CSS Path)");

  const initialValues = {
    newsSourceId: "",
    isDynamicPage: false,
    name: "",
    listPath: "",
    urlPath: "",
    datePathType: "DateInList",
    datePath: "",
    dateFormat: "",
    maxPageCount: "",
    nextPagePath: "",
    paginationType: "",
    titlePath: "",
    articlePath: "",
    scrapeDatePathTemplates: [
      {
        datePath: "",
        order: 1,
        dateAttribute: "",
      },
    ],
    scrapeDateFormats: [
      {
        dateFormat: ""
      }
    ]
  };

  const DatePathType = [
    { value: 1, label: "DateInList" },
    { value: 2, label: "DateInNews" },
  ];
  const MultiDatePathType = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
  ];
  const PaginationType = [
    { value: 1, label: "NextPageButton" },
    { value: 2, label: "LoadButton" },
    { value: 3, label: "InfiniteScroll" },
  ];

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    createTemplates,
    { isLoading, error, isSuccess },
  ] = useCreateTemplatesMutation();

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      onHide();
    }
  }, [isLoading]);

  const CreateSchema = Yup.object().shape({
    name: Yup.string().required("Ad daxil etməyiniz tələb olunur."),
    listPath: Yup.string().required("List Path daxil etməyiniz tələb olunur."),
    // urlPath: Yup.string().required("Url  daxil etməyiniz tələb olunur."),
    scrapeDatePathTemplates: Yup.array()
      .required("Kateqoriya və order boş ola bilməz")
      .of(
        Yup.object().shape({
          datePath: Yup.string().required(
            "Kateqoriya daxil etməyiniz tələb olunur"
          ),
        })
      ),
      scrapeDateFormats: Yup.array()
      .required("Date Format  daxil etməyiniz tələb olunur.")
      .of(
        Yup.object().shape({
          dateFormat: Yup.string().required(
            "Date Format  daxil etməyiniz tələb olunur."
          ),
        })
      ),

    maxPageCount: Yup.string().required(
      "Max Page Count  daxil etməyiniz tələb olunur."
    ),
    nextPagePath: Yup.string().when("paginationType", {
      is: "InfiniteScroll",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required(
        "Next Page Path daxil etməyiniz tələb olunur."
      ),
    }),

    titlePath: Yup.string().required(
      "Title Path  daxil etməyiniz tələb olunur."
    ),
    articlePath: Yup.string().required(
      "Article Path  daxil etməyiniz tələb olunur."
    ),
    paginationType: Yup.string().required(
      "Pagination Type  daxil etməyiniz tələb olunur."
    ),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const onPostHumanResource = (values) => {
    const humanResource = {
      newsSourceId: id,
      name: values?.name,
      listPath: values?.listPath,
      // urlPath: values?.urlPath,
      datePathType: "DateInList",

      dateAttribute: values?.dateAttribute,
      scrapeDateFormats: values?.scrapeDateFormats,
      maxPageCount: values?.maxPageCount,
      ...(values?.paginationType != "InfiniteScroll" && {
        nextPagePath: values?.nextPagePath,
      }),
      ...(values?.paginationType != "InfiniteScroll" && {
        isDynamicPage: values?.isDynamicPage,
      }),

      datePathType: values?.datePathType,
      paginationType: values?.paginationType,
      titlePath: values?.titlePath,
      articlePath: values?.articlePath,
      scrapeDatePathTemplates: values.scrapeDatePathTemplates.map(
        (item, index) => {
          return {
            ...item,
            order: index + 1,
          };
        }
      ),
    };

    if (!values?.dateAttribute) {
      delete humanResource.dateAttribute;
    }
    createTemplates(humanResource);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostHumanResource(values, resetForm, setSubmitting);
    },
  });

  const { resetForm, values, setFieldValue, setValues } = formik;
  
  useEffect(() => {
    if (values?.datePathType == "DateInList") {
      setDatePathString("Date Path (CSS Path)");
    } else {
      setDatePathString("Date Path (XPath)");
    }
  }, [values]);

  useEffect(() => {
    if (values?.paginationType == "InfiniteScroll") {
      setIsInfinity(true);
    } else {
      setIsInfinity(false);
    }
  }, [values]);



  function handleDatePathType(value) {
    setFieldValue("datePathType", value?.label);
    setOption(value);
  }

  function handlePaginationType(value) {
    setFieldValue("paginationType", value?.label);
  }
  function scrapeDatePathTemplatesChanges(value, index) {
    formik.setFieldValue(
      `scrapeDatePathTemplates[${index}].order`,
      value.value
    );
  }

  const addItem = () => {
    const scrapeDatePathTemplates = [
      ...values.scrapeDatePathTemplates,
      {
        datePath: "",
        dateAttribute: "",
      },
    ];

    setValues({ ...values, scrapeDatePathTemplates });
  };
  const addDateFormatItem = () => {
    const scrapeDateFormats = [
      ...values.scrapeDateFormats,
      {
        dateFormat: "",
       
      },
    ];

    setValues({ ...values, scrapeDateFormats });
  };
  const handleRemoveItem = (index) => {
    let scrapeDatePathTemplates = [...values.scrapeDatePathTemplates];
    scrapeDatePathTemplates = scrapeDatePathTemplates.filter(
      (_, i) => i !== index
    );
    setValues({ ...values, scrapeDatePathTemplates });
  };

  const handleRemoveFormatItem = (index) => {
    let scrapeDateFormats = [...values.scrapeDateFormats];
    scrapeDateFormats = scrapeDateFormats.filter(
      (_, i) => i !== index
    );
    setValues({ ...values, scrapeDateFormats });
  };
  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Əlavə et</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="name"
                    name="name"
                    label="Ad"
                    placeholder="Ad"
                    className="w-100"
                    error={getInputClasses("name")}
                    {...formik.getFieldProps("name")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf condition={formik.touched.name && formik.errors.name}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="listPath"
                    name="listPath"
                    label="List Path (CSS Path)"
                    placeholder="List Path (CSS Path)"
                    className="w-100"
                    error={getInputClasses("listPath")}
                    {...formik.getFieldProps("listPath")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.listPath && formik.errors.listPath}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.listPath}
                    </div>
                  </div>
                </RenderIf>
              </div>

              {/* <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="urlPath"
                    name="urlPath"
                    label="Url Path"
                    placeholder="Url Path"
                    className="w-100"
                    error={getInputClasses("urlPath")}
                    {...formik.getFieldProps("urlPath")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.urlPath && formik.errors.urlPath}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.urlPath}</div>
                  </div>
                </RenderIf>
              </div> */}

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <ReactSelect
                    suggestions={DatePathType}
                    handleChangeSingle={(value) => handleDatePathType(value)}
                    label="Date Path Type"
                    placeholder="Date Path Type"
                    inputId="datePathType"
                    single={option}
                  />
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-6"></div>

              <ScrapeTemplatePathPart
                getFieldProps={formik.getFieldProps}
                getInputCheck={getInputClasses}
                addItem={addItem}
                scrapeTemplateOptions={MultiDatePathType}
                scrapeDatePathTemplatesChanges={scrapeDatePathTemplatesChanges}
                datePathString={datePathString}
                values={formik.values}
                touched={formik.touched}
                errors={formik.errors}
                handleRemoveItem={handleRemoveItem}
              />

              <div className="col-12 col-md-12 col-lg-12"></div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <DatePathPart
                getFieldProps={formik.getFieldProps}
                getInputCheck={getInputClasses}
                addItem={addDateFormatItem}
                values={formik.values}
                touched={formik.touched}
                dateFormatString={"Date Format"}
                errors={formik.errors}
                handleRemoveItem={handleRemoveFormatItem}
              />
                </div>
           
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="maxPageCount"
                    name="maxPageCount"
                    label="Max Page Count"
                    placeholder="Max Page Count"
                    className="w-100"
                    error={getInputClasses("maxPageCount")}
                    {...formik.getFieldProps("maxPageCount")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.maxPageCount && formik.errors.maxPageCount
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.maxPageCount}
                    </div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <ReactSelect
                    suggestions={PaginationType}
                    handleChangeSingle={(value) => handlePaginationType(value)}
                    label="Pagination Type"
                    placeholder="Pagination Type"
                    inputId="paginationType"
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.paginationType &&
                    formik.errors.paginationType
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.paginationType}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                {!isInfinity && values?.paginationType && (
                  <>
                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                      <TextField
                        id="nextPagePath"
                        name="nextPagePath"
                        label="Next Page Path (XPath)"
                        placeholder="Next Page Path (XPath)"
                        className="w-100"
                        error={getInputClasses("nextPagePath")}
                        {...formik.getFieldProps("nextPagePath")}
                        onKeyDown={handleEnter}
                      />
                    </div>

                    <RenderIf
                      condition={
                        formik.touched.nextPagePath &&
                        formik.errors.nextPagePath
                      }
                    >
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.nextPagePath}
                        </div>
                      </div>
                    </RenderIf>
                  </>
                )}
              </div>
              {!isInfinity && values?.paginationType && (
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          name="isDynamicPage"
                          id="isDynamicPage"
                          label="isDynamicPage"
                          checked={formik?.values?.isDynamicPage}
                          placeholder="isDynamicPage"
                          error={getInputClasses("isDynamicPage")}
                          {...formik.getFieldProps("isDynamicPage")}
                          onKeyDown={handleEnter}
                        />
                      }
                      label="Dynamic load data on scroll"
                    />
                  </div>
                </div>
              )}
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="titlePath"
                    name="titlePath"
                    label="Title Path (XPath)"
                    placeholder="Title Path (XPath)"
                    className="w-100"
                    error={getInputClasses("titlePath")}
                    {...formik.getFieldProps("titlePath")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.titlePath && formik.errors.titlePath
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.titlePath}
                    </div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="articlePath"
                    name="articlePath"
                    label="Article Path (XPath)"
                    placeholder="Article Path (XPath)"
                    className="w-100"
                    error={getInputClasses("articlePath")}
                    {...formik.getFieldProps("articlePath")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.articlePath && formik.errors.articlePath
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.articlePath}
                    </div>
                  </div>
                </RenderIf>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
           
            }}
          >
            Bağla
          </Button>
          <Button type="submit"  className={`px-9`}>
            Əlavə et
            {isLoading && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
